import useIsMobile from '@/lib/hooks/useIsMobile';
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/shadcn/ui/sheet"
import { useState } from 'react';
import { ScrollArea } from "@/shadcn/ui/scroll-area"
import AjuntamentBrandBanner from '@/icons/AjuntamentBrandBanner';
import { useTranslation } from 'react-i18next';

export default function AuthBackground({
    modalContent = <></>,
    mobileSubmitButton = <></>,
    mobile = {
        sheetTitle: '',
        sheetContent: () => { },
        sheetTrigger: <></>,
        styles: {
            parentDiv: '',
            trigger: '',
            header: '',
            content: '',
            title: ''
        },
    }

}) {
    const { t } = useTranslation(['translation', 'messages'])
    const { isMobile } = useIsMobile()

    return (
        <div className='w-full h-screen justify-center items-center lg:bg-ead bg-cover bg-center flex flex-col lg:flex-row'>
            <div className='flex flex-col lg:grid h-full lg:h-fit max-h-[95%] overflow-y-auto p-[64px] gap-[48px] lg:gap-[128px] w-full lg:w-fit lg:grid-cols-[296px_400px] bg-white text-foreground rounded-3xl'>
                <div className='flex justify-between'>
                    <div className='w-full h-fit lg:h-full flex flex-col gap-4'>
                        <div className='flex flex-col gap-[48px] lg:gap-[32px] h-full'>
                            <div className='flex flex-col font-semibold'>
                                <span className='text-[30px] lg:text-5xl leading-[36px] lg:leading-[48px] tracking-[-0.6px] lg:tracking-[-1.92px]'>
                                    {t("guestInfo.HomePageMessageTop", { ns: "messages" })}
                                </span>
                                <span className='text-[30px] lg:text-5xl text-primary w-full leading-[36px] lg:leading-[48px] tracking-[-0.6px] lg:tracking-[-1.92px]'>
                                    {t("guestInfo.HomePageMessageBottom", { ns: "messages" })}
                                </span>
                            </div>

                            {
                                !isMobile
                                    ?
                                    <>
                                        <span className='h-full lg:h-fit text-lg leading-[24px] w-full font-medium'>
                                            {t("guestInfo.HomePageMessageDescription", { ns: "messages" })}
                                        </span>
                                        <div className='h-full flex items-end'>
                                            <AjuntamentBrandBanner />
                                        </div>
                                    </>

                                    :
                                    <>
                                        <div className='text-sm' dangerouslySetInnerHTML={{ __html: t("LoginInfoMessage", { ns: "translation" }) }} />

                                    </>
                            }

                        </div>

                    </div>
                    {
                        isMobile
                            ?
                            <AjuntamentBrandBanner />
                            :
                            <></>
                    }

                </div>
                {
                    isMobile
                        ?
                        <div className='w-full h-full lg:h-fit flex flex-col lg:items-end'>
                            <div className='flex flex-col h-full w-full items-center lg:items-end justify-between lg:justify-normal lg:w-3/4 3xl:w-[25rem] 3xl:max-w-[30rem]'>
                                {modalContent}
                            </div>

                        </div>
                        :
                        <>
                            {modalContent}
                        </>
                }

            </div>
            {
                isMobile
                    ?
                    <div className='w-full p-[64px]'>
                        {mobileSubmitButton}
                    </div>

                    :
                    <></>
            }
        </div>
    );
}